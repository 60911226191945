import React from "react";
import styled from 'styled-components';
import Popup from '../../02_atoms/Popup';
import Button from '../../02_atoms/Button';

const StyledHeadlineContainer = styled('div')`
    width: 100%;
    margin-bottom: 10px;
    font-size: 24px;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        margin-bottom: 30px;
        font-size: 26px;
    }
`;

const StyledBodyContainer = styled('div')`
    display: flex;
    flex-direction: column;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;
    }
`;

const StyledContentContainer = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const StyledTextContainer = styled('div')`
    color: red;
    margin-bottom: 30px;
`;

const StyledButtonsContainer = styled('div')`
    margin-top: auto;
    display: flex;
    justify-content: space-between;
`;

const ConfirmCancellationPopup = (props) => {
    const { showCancellationInfo, setShowPopup, callBack, loading } = props;

    return (
        <Popup setShowPopup={setShowPopup}>
            <StyledHeadlineContainer><h3>Order Cancellation</h3></StyledHeadlineContainer>

            <StyledBodyContainer>
                <StyledContentContainer>
                    <StyledTextContainer>
                        Are you sure you want to cancel your order?
                    </StyledTextContainer>
                    {showCancellationInfo &&
                        <StyledTextContainer>
                            Your order could no longer be canceled, it has progressed too far in the order process.
                        </StyledTextContainer>
                    }
                    <StyledButtonsContainer>
                        <Button disabled={showCancellationInfo} clickHandler={callBack} loading={loading} setShowPopUp={false} >Cancel Order</Button>
                    </StyledButtonsContainer>
                </StyledContentContainer>
            </StyledBodyContainer>
        </Popup>
    )
}

export default ConfirmCancellationPopup;
