import React from "react";
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Popup from '../../02_atoms/Popup';
import Button from '../../02_atoms/Button';
import { ReactComponent as ButtonArrow } from '../../../icons/arrow-right-long.svg';

const StyledHeadlineContainer = styled('div')`
    width: 100%;
    font-size: 24px;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        font-size: 26px;
    }
`;

const StyledBodyContainer = styled('div')`
    display: flex;
    flex-direction: column;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;
    }
`;

const StyledContentContainer = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    
    b {
        font-weight: ${props => props.theme.fontWeights.regular};
        color: ${props => props.theme.colors.pink};
    }
    
    p {
        margin-top: 0;
        margin-bottom: 30px;
    }
`;

const StyledButtonsContainer = styled('div')`
    margin-top: auto;
    display: flex;
    justify-content: space-between;
`;

const FirstTimeHerePopup = (props) => {
    const { setShowPopup } = props;

    return (
        <Popup setShowPopup={setShowPopup}>
            <StyledHeadlineContainer><h3>First time here?</h3></StyledHeadlineContainer>

            <StyledBodyContainer>
                <StyledContentContainer>
                    <p>Please note that we need your full name, private address, and pick up location to fulfill your order. You can edit this information anytime in your profile or by clicking the following button:</p>
                    <StyledButtonsContainer>
                        <Button>
                            <Link to='/profile'>Open your profile<ButtonArrow/></Link>
                        </Button>
                    </StyledButtonsContainer>
                </StyledContentContainer>
            </StyledBodyContainer>
        </Popup>
    )
}

export default FirstTimeHerePopup;
