import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowDownIcon} from '../../../icons/empty-arrow-down.svg';
import { ReactComponent as ReceivedIcon} from '../../../icons/order-received.svg';
import { ReactComponent as InProgressIcon} from '../../../icons/order-inprogress.svg';
import { ReactComponent as FulfilledIcon} from '../../../icons/order-fulfilled.svg';
import { ReactComponent as CancelledIcon} from '../../../icons/order-cancelled.svg';
import { Context } from '../../00_utilities/context';
import Button from "../../02_atoms/Button";
import urlFetch from "../../00_utilities/connectivity/urlFetch";
import ConfirmCancellationPopup from "../ConfirmCancellationPopup";

const StyledOrderElement = styled('div')`
    margin-bottom: 40px;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        margin-bottom: 20px;
    }
`;

const StyledOrderElementHeader = styled('div')`
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.fulfilled || props.cancelled ? '#8F8C8F' : props.theme.colors.pink};
    padding: 15px;
    
    .arrow-down-icon {
        fill: ${props => props.theme.colors.white};
        width: 21px;
        height: 21px;
        position: absolute;
        top: 15px;
        right: 20px;
        transform: rotate(${props => props.active ? '180deg' : '0deg'});

        path {
            fill: ${props => props.theme.colors.white};
        }

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            top: 34px;
            right: 34px;
            width: 33px;
            height: 33px;
        }
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        padding: 30px 80px 30px 30px;
    }
    
    &:hover {
        cursor: pointer;
    }
`;

const StyledHeaderItemParagraph = styled('p')`
    margin: 0;
    font-size: ${props => props.highlighted ? '20px' : '16px'};
    
    &:first-child {
        font-weight: ${props => props.theme.fontWeights.medium};
    }
`;

const StyledOrderElementHeaderItem = styled('div')`
    margin-bottom: 15px;
    
    &:nth-child(1) {
        width: 100%;
        min-width: 320px;
    }
    
    &:nth-child(2) {
        height: 80px;
        width: 100%;
        min-width: 80px;
        min-height: 50px;
        text-align: center;
        margin-bottom: 20px;
    }
    
    &:nth-child(3) {
        min-width: 150px;
        width: 65%;
    }
    
    &:nth-child(4) {
        min-width: 100px;
        width: 35%;
    }

    &:nth-child(5) {
        height: 80px;
        width: 100%;
        min-width: 80px;
        min-height: 50px;
        text-align: center;
        margin-bottom: 20px;
    }
    
    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        height: auto;
        margin-right: 80px;

        &:nth-child(1) {
            width: auto;
        }

        &:nth-child(2) {
            width: auto;
            height: auto;
            margin-bottom: 5px;
        }
        
        &:nth-child(3) {
            width: auto;
        }

        &:nth-child(4) {
            width: auto;
            margin-right: 0;
        }

        &:nth-child(5) {
            width: auto;
            height: auto;
            margin-bottom: 5px;
        }
    }
`;

const StyledOrderElementBody = styled('div')`
    padding: 40px 15px;
    border: 1px solid #434549;
    border-top: none;
    display: ${props => props.active ? 'block' : 'none'};

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        padding: 40px 30px;
    }
`;

const StyledToolTip = styled('div')`
  position: relative;

  .box {
      position: absolute;
      bottom: calc(100% + 5px);
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      background: ${props => props.theme.colors.black};
      padding: 5px;
      color: ${props => props.theme.colors.white};
      opacity: 0;
      visibility: hidden;
      max-height: 0;
      -webkit-transition: 0.4s ease all;
      transition: 0.4s ease all;
      font-size: 10px;
      width: max-content;

    &::before {
      content: "";
      position: absolute;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid ${props => props.theme.colors.black};
      width: 0;
      height: 0;
      bottom: -6px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:hover .box {
    opacity: 1;
    visibility: visible;
    max-height: 200px;
  }
`;

const StyledOrderStateMarksContainer = styled('div')`
    display: inline-flex;
    align-items: flex-end;
    height: 100%;
`;

const StyledOrderStateMark = styled('div')`
    border: 1px solid white;
    width: 14px;
    height: 14px;
    background-color: ${props => props.active ? 'white' : 'transparent'};
    position: relative;
    display: flex;
    justify-content: center;
    
    svg {
        position: absolute;
        bottom: 26px;
        width: 42px;
        height: 42px;
        
        path {
            fill: ${props => props.theme.colors.white};
        }

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            width: 34px;
            height: 34px;
            bottom: 25px;
        }
    }

    &:not(:last-child) {
        margin-right: 25px;

        &:after {
            content: '';
            position: absolute;
            left: 14px;
            top: 7px;
            background-color: ${props => props.theme.colors.white};
            width: 26px;
            height: 1px;
        }
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        width: 10px;
        height: 10px;

        &:not(:last-child) {
            margin-right: 15px;

            &:after {
                content: '';
                position: absolute;
                left: 10px;
                top: 4px;
                background-color: ${props => props.theme.colors.white};
                width: 16px;
                height: 1px;
            }
        }
    }
`;

const StyledOrderItemElement = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    border-bottom: 1px solid black;
    
    &:first-child {
        padding-top: 0;
    }
    
    &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;
    }
`;

const StyledOrderItemImageContainer = styled('div')`
    overflow: hidden;
    margin-bottom: 40px;
    width: 100%;
    height: 230px;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        width: 400px;
        margin-right: 40px;
        margin-bottom: 0;
    }
`;

const StyledOrderItemTextContainer = styled('div')`
    p {
        margin-top: 0;
        margin-bottom: 5px;
    }
    
    p:first-child {
        font-size: 26px;
        font-weight: ${props => props.theme.fontWeights.medium};
    }
    
    p:nth-child(2) {
        font-size: 20px;
        font-weight: ${props => props.theme.fontWeights.medium};
    }
    
    p:nth-child(3) {
        font-size: 13px;
        color: #B4B1B3;
    }
    
    p:nth-child(4) {
        font-size: 16px;
        font-weight: ${props => props.theme.fontWeights.medium};
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        p:first-child {
            font-size: 24px;
        }

        p:nth-child(2) {
            font-size: 20px;
            font-weight: ${props => props.theme.fontWeights.medium};
        }

        p:nth-child(3) {
            font-size: 16px;
            color: ${props => props.theme.colors.black};
        }

        p:nth-child(4) {
            font-size: 16px;
            font-weight: ${props => props.theme.fontWeights.medium};
        }
    }
`;

function OrderElement (props) {
    const { order: initialOrder, initiallyActive } = props; // Rename order to initialOrder
    const [order, setOrder] = React.useState(initialOrder); // Use useState to manage order
    const items = order.items ? Object.values(order.items) : [];
    const [active, setActive] = React.useState(initiallyActive);
    const context = React.useContext(Context);
    const orderItemElements = [];
    const [showCancellationPopup, setShowCancellationPopup] = React.useState(false);
    const [showCancellationInfo, setShowCancellationInfo] = React.useState(false);
    const [cancelLoading, setCancelLoading] = React.useState(false);
    const orderStates = [
        {
            title: 'Received',
            description: 'Your order has been successfully placed',
            icon: <ReceivedIcon/>
        },
        {
            title: 'In Progress',
            description: 'Your order is in progress',
            icon: <InProgressIcon/>
        },
        {
            title: 'Fulfilled',
            description: 'Your order has been fulfilled',
            icon: <FulfilledIcon/>
        },
        {
            title: 'Cancelled',
            description: 'Your order has been cancelled',
            icon: <CancelledIcon/>
        }
    ];

    const getOrderProgressLevel = (state) => {
        const states = ['received', 'inprogress', 'fulfilled', 'cancelled'];

        return state !== null ? states.indexOf(state) : 0;
    }

    const orderStateOptions = orderStates[getOrderProgressLevel(order.state)];

    const getOrderStateElement = (state) => {
        const progressLevel = getOrderProgressLevel(state);
        const orderStateMarks = [];

        for (let i = 0; i < orderStates.length; i += 1) {
            const active = i === progressLevel;

            const icon = active ? orderStateOptions.icon : null;
            const orderStateMark = <StyledOrderStateMark
                key={`order-state-mark-${i}`}
                className='order-state-mark'
                active={active}>{icon}</StyledOrderStateMark>;

            orderStateMarks.push(orderStateMark);
        }

        return <StyledOrderStateMarksContainer>{orderStateMarks}</StyledOrderStateMarksContainer>
    }

    const getOrderCancelButton = (state) => {
        const progressLevel = getOrderProgressLevel(state);

        if (progressLevel === 3) {
            return null;
        }


        let disabled = !(order.isCancellable && progressLevel !== 2 && progressLevel !== 3);
        let cancelButton = <Button inverted disabled={disabled} clickHandler={(event) => {cancelOrderPopUp(event)}} >Cancel Order</Button>;
        let cancelButtonWithTooltip = <StyledToolTip>
                {cancelButton}
                <div className="box">Your order could no longer be canceled, it has progressed too far in the order process.</div>
            </StyledToolTip>;

        return <StyledOrderStateMarksContainer>
            {!disabled ? cancelButton : cancelButtonWithTooltip}
        </StyledOrderStateMarksContainer>
    }

    const cancelOrderPopUp = (event) => {
        event.stopPropagation();
        setShowCancellationPopup(true);
    }

    const cancelOrder = () => {
        setCancelLoading(true);
        urlFetch('/api/orders/cancel-order', { successCallback: updateOrder }, null, 'POST', null, { order_id: order.id }, context.token);
    }

    const updateOrder = (response) => {
        setCancelLoading(false);
        setOrder(response.order);
        setShowCancellationInfo(!response.success)
        setShowCancellationPopup(!response.success);
    }

    for (let j = 0; j < items.length; j += 1) {
        const orderItem = items[j];
        const priceInfoText = orderItem.product.is_self_paid ? <>(incl. VAT. <a href={context.faqUrl} target='_blank' rel="noreferrer">More information.</a>)</> : '(per packaging unit)';

        orderItemElements.push(
            <StyledOrderItemElement key={`order-item-${j}`}>
                <StyledOrderItemImageContainer dangerouslySetInnerHTML={{ __html: orderItem.product.image.list }}></StyledOrderItemImageContainer>
                <StyledOrderItemTextContainer>
                    <p>{orderItem.product.title}</p>
                    <p>{orderItem.product.price} € {priceInfoText}</p>
                    { orderItem.size &&
                        <p>Size</p> &&
                        <p>{orderItem.size}</p>
                    }
                    <p>Quantity</p>
                    <p>{orderItem.count}</p>
                </StyledOrderItemTextContainer>
            </StyledOrderItemElement>
        )
    }

    return (
        <StyledOrderElement>
            <StyledOrderElementHeader active={active} fulfilled={getOrderProgressLevel(order.state) === 2} cancelled={getOrderProgressLevel(order.state) === 3} onClick={() => { setActive(!active) }}>
                <StyledOrderElementHeaderItem>
                    <StyledHeaderItemParagraph highlighted>Order {order.number}</StyledHeaderItemParagraph>
                    <StyledHeaderItemParagraph>{orderStateOptions.description}</StyledHeaderItemParagraph>
                </StyledOrderElementHeaderItem>

                <StyledOrderElementHeaderItem>
                    { getOrderStateElement(order.state) }
                </StyledOrderElementHeaderItem>

                {/*<StyledOrderElementHeaderItem>
                    { getOrderCancelButton(order) }
                </StyledOrderElementHeaderItem>*/}

                <StyledOrderElementHeaderItem>
                    <StyledHeaderItemParagraph>Order placed</StyledHeaderItemParagraph>
                    <StyledHeaderItemParagraph>{order.date}</StyledHeaderItemParagraph>
                </StyledOrderElementHeaderItem>

                <StyledOrderElementHeaderItem>
                    <StyledHeaderItemParagraph>Total</StyledHeaderItemParagraph>
                    <StyledHeaderItemParagraph>{ order.sum } €</StyledHeaderItemParagraph>
                </StyledOrderElementHeaderItem>

                <StyledOrderElementHeaderItem>
                    { getOrderCancelButton(order.state) }
                </StyledOrderElementHeaderItem>

                <ArrowDownIcon className='arrow-down-icon'/>
            </StyledOrderElementHeader>

            <StyledOrderElementBody active={active}>
                { orderItemElements }
            </StyledOrderElementBody>

            { showCancellationPopup && <ConfirmCancellationPopup showCancellationInfo={showCancellationInfo} order={order} loading={cancelLoading} setShowPopup={setShowCancellationPopup} callBack={cancelOrder} /> }
        </StyledOrderElement>
    )
}

export default OrderElement;
