import React from "react";
import styled from "styled-components";
import LoadingIcon from '../LoadingIcon';

const StyledButton = styled('div')`
    background-color: ${props => props.inverted ? props.theme.colors.white : props.theme.colors.pink};
    border: 1px solid ${props => props.theme.colors.pink};
    border-radius: 5px;
    padding: 9px 18px;
    color: ${props => props.inverted ? props.theme.colors.pink : props.theme.colors.white};
    display: inline-block;
    font-size: 16px;
    width: ${props => props.width ? props.width + "px" : 'auto'};
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};;

    a,
    .interactive-link {
        font-weight: ${props => props.theme.fontWeights.medium};
        text-decoration: none;
        color: ${props => props.inverted ? props.theme.colors.pink : props.theme.colors.white};
        padding: 9px 18px;
        margin: 0 -18px;
        white-space: nowrap;
    }

    svg {
        width: 18px;
        height: 10px;
        padding-left: 5px;
        fill: ${props => props.inverted ? props.theme.colors.pink : props.theme.colors.white};
        
        path {
            fill: ${props => props.inverted ? props.theme.colors.pink : props.theme.colors.white};
        }

        rect {
            fill: ${props => props.inverted ? props.theme.colors.pink : props.theme.colors.white};
        }
    }

    &:hover {
        cursor: pointer;
        background-color: ${props => props.theme.colors.pinkDark};
        color: ${props => props.inverted ? props.theme.colors.white : props.theme.colors.pink};
        
        a,
        .interactive-link {
            text-decoration: none;
            color: ${props => props.theme.colors.white};
        }

        svg {
            fill: ${props => props.theme.colors.white};
            
            path {
                fill: ${props => props.theme.colors.white};  
            } 
            
            rect {
                fill: ${props => props.theme.colors.white};  
            } 
        }
    }
    
    ${props => {
        if (props.$loading) {
            return `
                color: transparent;
                position: relative;
                
                a,
                .interactive-link {
                    color: transparent;
                }
                
                svg {
                    opacity: 0;
                }
                
                .loading-icon {
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    display: inline;
                    left: calc(50% - 13px);
                }
                
                &:hover {
                    a,
                    .interactive-link {
                        color: transparent;
                    }
                }
            `
        }
        
        if (props.disabled) {
            return `
                pointer-events: none;
                opacity: 0.65;
            `
        }
    }}
`;

function Button(props) {
    const { children, clickHandler, loading, disabled, width, textAlign } = props;
    const inverted = 'inverted' in props ? props.inverted : false;

    return (
        <StyledButton className='button' onClick={clickHandler} inverted={inverted} width={width} textAlign={textAlign} $loading={loading} disabled={disabled}>{children} {loading && <LoadingIcon/>}</StyledButton>
    );
}

export default Button;
