import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ReceivedIcon} from '../../../icons/order-received.svg';
import { ReactComponent as InProgressIcon} from '../../../icons/order-inprogress.svg';
import { ReactComponent as FulfilledIcon} from '../../../icons/order-fulfilled.svg';
import { ReactComponent as CancelledIcon} from '../../../icons/order-cancelled.svg';

const StyledOrderSliderElement = styled('div')`
    display: flex;
    flex-direction: column;
    min-width: ${props => props.elementWidth}px;
    margin-bottom: 40px;

    @media (min-width: ${props => props.theme.breakpoints.max.breakpoint}px) {
        /*margin-bottom: 20px;
        flex-direction: row;*/
    }
`;

const StyledOrderSliderElementHeader = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
    h4 {
        margin: 0;
    }

    @media (min-width: ${props => props.theme.breakpoints.max.breakpoint}px) {
        margin-right: 60px;
        padding-left: 60px;
    }
`;

const StyledOrderSliderElementBody = styled('div')`
    padding: 0 40px;
    display: flex;
    justify-content: center;

    @media (min-width: ${props => props.theme.breakpoints.max.breakpoint}px) {
        padding: 0;
    }
`;

const StyledOrderStateMarksContainer = styled('div')`
    display: inline-flex;
    align-items: flex-end;
    height: 90px;
`;

const StyledOrderStateMark = styled('div')`
    border: 1px solid ${props => props.theme.colors.black};
    width: 14px;
    height: 14px;
    background-color: ${props => props.active ? props.theme.colors.black : 'transparent'};
    position: relative;
    display: flex;
    justify-content: center;
    
    svg {
        position: absolute;
        bottom: 35px;
        width: 48px;
        height: 48px;
        
        path {
            fill: ${props => props.theme.colors.black};
        }
    }

    &:not(:last-child) {
        margin-right: 25px;

        &:after {
            content: '';
            position: absolute;
            left: 14px;
            top: 7px;
            background-color: ${props => props.theme.colors.black};
            width: 26px;
            height: 1px;
        }
    }
`;

function OrderSliderElement (props) {
    const { order, elementWidth } = props;
    const orderStates = [
        {
            title: 'Received',
            description: 'Your order has been successfully placed',
            icon: <ReceivedIcon/>
        },
        {
            title: 'In Progress',
            description: 'Your order is in progress',
            icon: <InProgressIcon/>
        },
        {
            title: 'Fulfilled',
            description: 'Your order has been fulfilled',
            icon: <FulfilledIcon/>
        },
        {
            title: 'Cancelled',
            description: 'Your order has been cancelled',
            icon: <CancelledIcon/>
        }
    ];

    const getOrderProgressLevel = (state) => {
        const states = ['received', 'inprogress', 'fulfilled', 'cancelled'];

        return state !== null ? states.indexOf(state) : 0;
    }

    const orderStateOptions = orderStates[getOrderProgressLevel(order.state)];

    const getOrderStateElement = (state) => {
        const progressLevel = getOrderProgressLevel(state);
        const orderStateMarks = [];

        for (let i = 0; i < orderStates.length; i += 1) {
            const active = i === progressLevel;

            const icon = active ? orderStateOptions.icon : null;
            const orderStateMark = <StyledOrderStateMark
                key={`order-state-mark-${i}`}
                className='order-state-mark'
                active={active}>{icon}</StyledOrderStateMark>;

            orderStateMarks.push(orderStateMark);
        }

        return <StyledOrderStateMarksContainer>{orderStateMarks}</StyledOrderStateMarksContainer>
    }

    return (
        <StyledOrderSliderElement elementWidth={elementWidth}>
            <StyledOrderSliderElementHeader>
                <h4>Order {order.number}</h4>
                <p>{orderStateOptions.description}</p>
            </StyledOrderSliderElementHeader>
            <StyledOrderSliderElementBody>{ getOrderStateElement(order.state) }</StyledOrderSliderElementBody>
        </StyledOrderSliderElement>
    )
}

export default OrderSliderElement;
