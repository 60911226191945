import React from "react";
import styled from "styled-components";
import ArrowDownIcon from '../../../icons/empty-arrow-down.svg';
import Select from '../Select';
import CountrySelect from '../CountrySelect';

const StyledInputContainer = styled('div')`
    width: 100%;
    margin-bottom: ${props => props.isEditing ? '30px' : '0'};
    position: relative;
    
    label {
        position: absolute;
        color: #B4B1B3;
        font-size: 13px;
        height: 20px;
        width: calc(100% - 30px);
        background-color: ${props => props.theme.colors.white};
        top: 1px;
        left: 0;
        bottom: 0;
        pointer-events: none;

        ${props => {
            if (!props.isEditing) {
                return '';
            }

            return `
                left: 15px;
            `;
        }}
    }
    
    input,
    select,
    textarea {
        box-sizing: border-box;
        appearance: none;
        border-radius: 4px;
        font-size: 16px;
        padding: 22px 20px 8px 0;
        border: 1px solid transparent;
        width: 100%;
        color: ${props => props.theme.colors.black};
        font-family: 'BrainlabNowW05', sans-serif;
        position: relative;

        ${props => {
            if (!props.isEditing) {
                return '';
            }

            return `
                padding: 22px 20px 8px 15px;
                border: 1px solid #DFDEDF;
            `;
        }}

        &:empty + label {
            padding-top: 16px;
        }

        &:valid + label,
        &:disabled + label,
        &:placeholder-shown + label {
            padding-top: 6px;
        }

        &:focus + label {
            top: 2px;
            padding-top: 5px;
        }
        
        &:disabled {
            background-color: ${props => props.theme.colors.white};
            opacity: 1;
        }
    }
    
    textarea {
        resize: none;
        height: 130px;
    }
    
    select {
        ${props => {
            if (props.isEditing) {
                return `
                        background: url(${ArrowDownIcon}) no-repeat calc(100% - 15px) calc(50%);
                        background-size: 14px;
                        background-color: ${props => props.theme.colors.white};
                `;
            }
        }}
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        width: ${props => props.componentWidth ? props.componentWidth : '50%'};
    }
`;

const StyledInputInnerWrapper = styled('div')`
    position: relative;
`;

const StyledLetterCounter = styled('div')`
    position: absolute;
    bottom: -20px;
    right: 0;
    font-size: 14px;
    color: #B4B1B3;
`;

const FormInput = (props) => {
    const { name, value, label, changeHandler, required = true, isSelect, isTextArea, isEditing, componentWidth, options, companyCode, internalCostCenter, isCountrySelect = false } = props;
    let letterCounter = null;
    const limit = 500;
    const [letterCount, setLetterCount] = React.useState(0);

    React.useEffect(() => {
        if (value) {
            setLetterCount(value.length);
        }
    }, [value]);

    let input = (
        <input type='text' key={`text-input-${name}`} name={name} defaultValue={value} onKeyUp={changeHandler} required={required} disabled={!isEditing}/>
    );

    if (isTextArea) {
        input = (
            <textarea key={`select-${name}`} name={name} defaultValue={value} onKeyUp={(e) => { setLetterCount(e.currentTarget.value.length); changeHandler(e); }} required disabled={!isEditing} placeholder='-' maxLength={limit}/>
        )

        letterCounter = isEditing && (
            <StyledLetterCounter>{letterCount} / {limit}</StyledLetterCounter>
        )
    }

    if (isCountrySelect) {
        input = (
            <CountrySelect key={`select-${name}`} name={name} value={value} changeHandler={changeHandler} isEditing={isEditing}/>
        )
    }

    if (isSelect && !companyCode) {
        input = (
            <Select name='officeLocation'
                    options={options}
                    value={value}
                    title={'Pick up Location'}
                    isEditing={isEditing}
                    disabled={!isEditing}
                    changeHandler={changeHandler}
            />
        )
    }

    if (isSelect && companyCode) {
        input = (
            <Select name='companyCode'
                    options={options}
                    value={value}
                    title={'Company Code'}
                    isEditing={isEditing}
                    disabled={!isEditing}
                    changeHandler={changeHandler}
            />
        )
    }

    if (isSelect && internalCostCenter) {
        input = (
            <Select name='internalCostCenter'
                    options={options}
                    value={value}
                    title={'Internal cost center'}
                    isEditing={isEditing}
                    disabled={!isEditing}
                    changeHandler={changeHandler}
            />
        )
    }

    return (
        <StyledInputContainer className={'form-input-container'} isEditing={isEditing} componentWidth={componentWidth}>
            <StyledInputInnerWrapper>
                { input }
                <label htmlFor={name}>{label}</label>
                { letterCounter }
            </StyledInputInnerWrapper>
        </StyledInputContainer>
    )
}

export default FormInput;
